@import "../../sass/abstracts/variables";
@import "../../sass/abstracts/mixins";

.footer {
  height: 15rem;
  margin: 4rem 0 0;
  background-color: $color-brown-light;
  box-shadow: 0 -1rem 2rem rgba(0, 0, 0, 0.15);

  &__logo {
    display: block;
    height: 11rem;
    margin: 0 auto;
    color: $color-yellow;
    transition: all .3s;
    &:hover {
      cursor: pointer;
      transform: scale(1.2);
    }

    &:active {
      cursor: pointer;
      transform: scale(1);
    }
  }

  &__copyright {
    font-family: "Amatic SC";
    color: $color-yellow;
    font-weight: 700;
    font-size: 2rem;
  }
}

.row {
  display: block;
  border-top: 1px solid $color-yellow;
  width: 80%;
  height: 3rem;
  margin: 0 auto;
}
