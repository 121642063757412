@import "../../sass/abstracts/variables";
@import "../../sass/abstracts/mixins";

.mobile-nav {
  display: none;

  @include respond(phone) {
    display: block;
    height: 15em;
    background-color: $color-yellow;
  }

  &__logo {
    @include respond(phone) {
      height: 15em;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }

  &__icon {
    position: relative;
    margin-top: 3.5rem;

    &,
    &::before,
    &::after {
      width: 3rem;
      height: 2px;
      background-color: #000;
      display: inline-block;
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 0;
      transition: all 0.2s;
    }

    &::before {
      top: -0.8rem;
    }

    &::after {
      top: 0.8rem;
    }
  }

  &__button {
    display: none;

    @include respond(phone) {
      display: block;
    }
  }

  &__checkbox:checked + &__button &__icon {
    background-color: transparent;
  }

  &__checkbox:checked + &__button &__icon::before {
    top: 0;
    transform: rotate(135deg);
  }

  &__checkbox:checked + &__button &__icon::after {
    top: 0;
    transform: rotate(-135deg);
  }

  &__checkbox {
    display: none;
  }

  &__checkbox:checked ~ .user-nav {
    @include respond(phone) {
      opacity: 1;
      transform: translateX(0);
      height: 55rem;
    }
  }

  &__button {
    background-color: #fff;
    height: 7rem;
    width: 7rem;
    position: absolute;
    top: 4rem;
    right: 5rem;
    border-radius: 50%;
    z-index: 100;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.1);
    text-align: center;
    cursor: pointer;
  }

  @media only screen and (max-width: 56.25em) {
    .mobile-nav__button {
      top: 4rem;
      right: 4rem;
    }
  }

  @media only screen and (max-width: 37.5em) {
    .mobile-nav__button {
      top: 3rem;
      right: 3rem;
    }
  }
}

.navbar-logo {
  height: 12em;

  @include respond(phone) {
    display: none !important;
  }
}

img {
  width: auto;
}

.user-nav {
  position: relative;
  display: flex;
  align-items: center;

  background-color: $color-yellow;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15);
  z-index: 100;

  transition: all 0.4s;

  @include respond(phone) {
    height: 100vh;
    transform: translateX(-100rem);
    opacity: 0;
    box-shadow: none;
  }

  &__checkbox {
    display: none;

    @include respond(phone) {
      display: block;
      width: 95%;
      background-color: $color-yellow;
    }
  }

  & > * {
    padding: 0 2rem;
    display: flex;
    align-items: center;
  }

  &__navbar {
    display: flex;
    align-items: center;

    @include respond(phone) {
      position: absolute;
      flex-direction: column;
      width: 97%;
      top: 5%;
      margin: 0 auto;
    }
  }

  &__collapse {
    width: 90vw;
    justify-content: space-between;
  }

  &__item {
    margin-right: 2.5em;
    list-style-type: none;
    transition: all 0.2s;

    &:hover {
      cursor: pointer;
      transform: scale(1.1);
      color: $color-brown-light;
      text-decoration: none;
    }

    &:active {
      cursor: pointer;
      transform: scale(1);
    }

    &--active {
      color: $color-brown-light !important;
    }
  }

  &__link {
    font-size: 4em;
    font-family: "Amatic SC", cursive;
    font-weight: 700;
    cursor: pointer;
    text-decoration: none;
    color: $color-brown-dark;

    &:hover {
      color: $color-brown-light;
      text-decoration: none;
    }

    @include respond(phone) {
      font-size: 6em;
    }
  }

  &__homepage {
    position: absolute;
    top: 1rem;
    left: 4.5rem;
    width: 7rem;
    height: 10rem;
    color: transparent;
  }
}

.footer {
  &__box {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__icon {
    width: 4rem;
    height: 4rem;
    margin-right: 1.5rem;
    transition: all 0.2s;
    fill: $color-brown-dark;

    @include respond(phone) {
        width: 5rem;
        height: 5rem;
    }

    &:hover {
      cursor: pointer;
      transform: scale(1.2);
    }

    &:active {
      cursor: pointer;
      transform: scale(1);

      }
    }
    &__icon-box {
        @include respond(phone) {
          position: absolute;
          width: 95%;
          bottom: 10%;
        }
  }
}
