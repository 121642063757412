@import "../../sass/abstracts/variables";
@import "../../sass/abstracts/mixins";

.content {
  padding: 1.5rem;
  margin-top: 10rem;
  color: #000;
  text-align: center;

  @include respond(phone) {
    margin-top: 2rem;
  }

  &__breadcrumb {
    .MuiTypography-root {
      font-size: 1.5rem;

      @include respond(phone) {
        font-size: 1.8rem;
      }
    }

    position: absolute;
    top: 13rem;

    @include respond(phone) {
      top: 16rem;
    }
  }

  &__image {
    width: 50%;
    margin: 4rem 0;

    @include respond(phone) {
      width: 90%;
    }
  }

  &__title {
    font-family: "Amatic SC", cursive;
    font-size: 7.7rem;
    color: $color-green;
  }

  &__text {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 2rem;
    padding: 0 7rem;
    text-align: left;

    @include respond(phone) {
      padding: 0 2rem;
      margin-bottom: 2rem;
    }
  }

  &__button {
    // position: absolute;
    // right: 1rem;
    // height: 5rem;
    // width: 12rem;

    @include respond(phone) {
      display: none;
    }

    &--print {
      position: absolute;
      margin-top: 2.5rem;
      right: 1rem;
      font-size: 3rem;
      font-family: "AMATIC SC";
      font-weight: 700;
      border: none;
      color: $color-brown-dark;
      background-color: transparent;
      cursor: pointer;
      transition: all 0.2s;

      &:hover {
        color: $color-brown-light;
        text-decoration: none;
        transform: scale(1.1);
        align-items: center;
      }

      &:active {
        transform: scale(1);
      }
    }
  }

  &__icon {
    position: absolute;
    top: -3rem;
    right: 4rem;
    width: 3rem;
    height: 3rem;
    transition: all 0.2s;
    fill: $color-brown-dark;
  }
}

p {
  margin-top: 10px;
}

.fb-comments {
  margin-top: 2rem;

  iframe {
    min-width: 320px !important;
  }
}

.fb-like {
  margin-top: 2rem;

  iframe {
    width: 210px !important;
  }
}

@media print {
  .no-print {
    display: none;
  }
  .print {
    display: block;
  }
}

.font-utility {
  position: fixed;
  right: 2rem;
  width: 3rem;
  height: 3rem;
  background-color: rgba($color-brown-light, 0.8);
  border-radius: 3px;
  cursor: pointer;
  transition: all .2s;
  font-size: 2.5rem;
  color: $color-brown-dark;
  z-index: 100;

  @include respond(phone) {
    right: .2rem;
  }

  &__increment {
    bottom: 16rem;
  }

  &__decrement {
    bottom: 12rem;
  }

  &:hover {
    transform: scale(1.1);
  }

  &:active {
    transform: scale(1);
  }

  &::selection {
    background: transparent;
  }
}

.visible {
  opacity: 1;
  transition: opacity .2s;
}

.hidden {
  opacity: 0;
}
