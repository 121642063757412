@import "../../sass/abstracts/variables";
@import "../../sass/abstracts/mixins";

.contact-form {
  &__group {
    display: block;
    width: 40rem;
    margin: 0 auto;
  }

  &__desc {
    margin: 5rem 0;
    font-size: 4rem;
    font-weight: 700;
    color: $color-brown-light;
  }

  &__label {
    margin: 0 0.5rem;
    font-size: 3rem;
  }

  &__input {
    width: 40rem;
    max-width: 40rem;
    border: 1px solid #ccc;
    border-radius: 0.3rem;
    padding: 1rem;
    margin: 0 1rem 2rem;
    color: #000;
  }

  &__error {
    margin-top: -2rem;
    font-size: 2rem;
    color: red;
  }

  &__header {
    position: relative;
    height: 45rem;
    background-image: linear-gradient(
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.7)
      ),
      url("../../../public/img/kelly-sikkema-4JxV3Gs42Ks_large.jpg");
    background-attachment: fixed;
    background-size: cover;
    background-position: top;
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15);

    @include respond(phone) {
      background-image: url("../../../public/img/kelly-sikkema-4JxV3Gs42Ks_small.jpg");
      background-attachment: initial;
      height: 35rem;
    }

    &--text {
      position: absolute;
      font-weight: 700;
      font-size: 6rem;
      top: 55%;
      left: 50%;
      width: 30%;
      transform: translate(-50%, -50%);
      text-align: center;
      color: $color-brown-light;
      opacity: 1;

      @include respond(phone) {
        width: 35%;
        font-size: 3rem;
      }
    }
  }

  &__text {
    padding: 3rem;
    font-size: 3rem;
    color: $color-brown-dark;
  }

  &__button {
    width: 20rem;
    height: 3rem;
    margin: 2rem 0 0;
    background-color: $color-brown-light;
    border: none;
    border-radius: 4px;
    color: $color-brown-dark;
    font-size: 2.5rem;
    font-family: inherit;
    font-weight: 700;
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
      transform: scale(1.1);
    }

    &:disabled {
      color: rgba($color-brown-dark, 0.2);
      background-color: rgba($color-brown-dark, 0.2);
      transform: scale(1);
      cursor: not-allowed;
    }
  }
}

.recaptcha {
  margin: 2rem auto;
  width: 304px;
}

textarea {
  height: 15rem;
}
