@import "../../sass/abstracts/_variables.scss";
@import "../../sass/abstracts/_mixins.scss";


h1 {
  color: black;
}

.about-us {
  &__content {
    padding: 5rem 0;
    &--text {
      padding: 7rem;
      font-size: 2rem;
      text-align: center;
    }
  }
}

.header__about-us {
  position: relative;
  height: 35rem;
  font-family: "Amatic SC";
  background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.7)
    ),
    url("../../../public/img/about-us_large.jpg");
  background-attachment: fixed;
  background-size: cover;
  background-position: bottom;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15);

  @include respond(phone) {
    background-image: url("../../../public/img/about-us_small.jpg");
  }
}
