// COLORS
$color-blue: #84BCDA;
$color-green: #93C592;
$color-brown-light: #F57D49;
$color-brown-dark: #6B4B3E;
$color-yellow: #FFD597;


// FONT
$default-font-size: 1.6rem;


// GRID
$grid-width: 114rem;
$gutter-vertical: 8rem;
$gutter-vertical-small: 6rem;
$gutter-horizontal: 6rem;
