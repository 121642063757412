@import "../../sass/abstracts/variables";
@import "../../sass/abstracts/mixins";

.cookie-info {
  position: fixed;
  background: $color-yellow;
  color: $color-brown-dark;
  line-height: 2rem;
  padding: 0.8rem 2rem;
  font-family: "Amatic SC";
  font-size: 2.5rem;
  text-align: center;
  bottom: 0;

  &__close {
    width: 25%;
    padding: 0.5rem 0.8rem;
    min-width: 20rem;
    border-radius: 0.5rem;
    cursor: pointer;
    font-weight: 700;
    font-size: 2.5rem;
    color: $color-brown-dark;
    background: #f57d49;
    margin: 1rem auto 0;

    &:hover {
      background-color: $color-brown-dark;
      color: $color-brown-light;
    }
  }
}

span {
  color: $color-brown-dark;
  line-height: 2rem;
  font-size: 2.5rem;
}
