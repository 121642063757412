@import "../../sass/abstracts/variables";
@import "../../sass/abstracts/mixins";

.fb-comments {
  margin-top: 100px;
}

main {
  font-family: "Amatic SC";
}

.header {
  position: relative;
  margin-top: 5rem;
  height: 35rem;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.7)
    ),
    url("../../../public/img/ben-mullins-5QTQz-oYk1A_large.jpg");
  background-attachment: fixed;
  background-size: cover;
  background-position: top;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15);

  @include respond(phone) {
    background-image: url("../../../public/img/ben-mullins-5QTQz-oYk1A_small.jpg");
    background-attachment: initial;
  }

  &__text {
    position: absolute;
    font-weight: 700;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 9rem;
    color: #fff;
    opacity: 1;
  }
}

.main {
  &__latest {
    display: flex;

    @include respond(phone, tab-port, tab-land) {
      flex-wrap: wrap;
      justify-content: center;
    }

    &--heading {
      margin: 7rem 0;
      font-weight: 700;
      font-size: 3rem;
      font-family: inherit;
    }

    // &--primary {
    //   position: relative;
    //   flex: 0 1 50%;
    //   max-width: 50%;

    //   @include respond(phone) {
    //     max-width: 100%;
    //     flex: 0 0 75%
    //   }
    // }

    &--secondary {
      position: relative;
      flex: 0 1 33%;
      max-width: 33%;

      @include respond(phone) {
        max-width: 100%;
        flex: 0 0 75%;
      }
    }
  }
}

.header__second {
  position: relative;
  margin-top: 10rem;
  height: 35rem;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.7)
    ),
    url("../../../public/img/guillaume-de-germain-fgmLRBlUIpc_large.jpg");
  background-attachment: fixed;
  background-size: cover;
  background-position: top;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15);

  @include respond(phone) {
    background-image: url("../../../public/img/guillaume-de-germain-fgmLRBlUIpc_small.jpg");
    background-attachment: initial;
  }

  &__text {
    position: absolute;
    font-weight: 700;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 9rem;
    color: #fff;
    opacity: 1;
  }
}
