@import "../../sass/abstracts/variables";
@import "../../sass/abstracts/mixins";

.fb-comments {
  margin-top: 100px;
}

.article {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-family: "Amatic SC", cursive;

  &__search {
    width: 40rem;
    height: 5rem;
    margin-top: 1rem;
    font-family: "Amatic SC", cursive;
    font-weight: 700;
    font-size: 3rem;

    @include respond(phone) {
      width: 25rem;
      height: 4rem;
      margin-top: 5rem;
    }
  }

  &__box {
    width: 25rem;
    height: 25rem;
    margin: 5rem 5rem;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    cursor: pointer;
    color: #000;
    transition: all 0.2s;
    text-decoration: none;

    @include respond(phone) {
      height: 27rem;
    }

    &:hover {
      cursor: pointer;
      transform: scale(1.1);
    }

    &:active {
      cursor: pointer;
      transform: scale(1);
    }
  }

  &__header {
    padding: 0.5rem 0;
    font-size: 3rem;

    @include respond(phone) {
      font-size: 3.5rem;
    }
  }

  &__content {
    font-size: 2rem;
    transition: all 0.1s ease-in;
    font-weight: 400;
    margin: 0 0 1rem;

    @include respond(phone) {
      font-size: 2.5rem;
    }
  }

  &__image {
    height: 15rem;
    max-width: 20rem;
  }
}
