@mixin clearfix {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin absCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

@mixin loadingBackground($opacity: 1) {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba($color-yellow, $opacity);
}

// MEDIA QUERY MANAGER
$breakpoints: (
  phone: 37.5em,
  tab-port: 56.25em,
  tab-land: 75em,
  desktop: 112.5em,
);
/*
0 - 600px:      Phone
600 - 900px:    Tablet portrait
900 - 1200px:   Tablet landscape
[1200 - 1800] is where our normal styles apply
1800px + :      Big desktop


$breakpoint arguement choices:
- phone
- tab-port
- tab-land
- big-desktop

ORDER: Base + typography > general layout + grid > page layout > components

1em = 16px

old version respond
*/
@mixin respondOld($breakpoint) {
//     @if $breakpoint == phone {
//         @media only screen and (max-width: 37.5em) { @content };    //600px
//     }
//     @if $breakpoint == tab-port {
//         @media only screen and (max-width: 56.25em) { @content };     //900px
//     }
//     @if $breakpoint == tab-land {
//         @media only screen and (max-width: 75em) { @content };    //1200px
//     }
    @if $breakpoint == big-desktop {
        @media only screen and (min-width: 112.5em) { @content };    //1800
    }
}

// new version
@mixin respond($keys...) {
  @each $key in $keys {
    @media (max-width: map-get($breakpoints, $key)) {
      @content;
    }
  }
}
