@import "sass/abstracts/mixins";
@import "sass/abstracts/variables";

@import "sass/base/base";
@import "sass/base/utilities";

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  height: 150px;
  color: white;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.scroll-top {
  position: fixed;
  right: 2rem;
  bottom: 2rem;
  width: 4rem;
  height: 4rem;
  background-color: rgba($color-brown-light, 0.8);
  border-radius: 3px;
  cursor: pointer;
  transform: translateY(0);
  transition: all 0.7s;

  @include respond(phone) {
    right: 0.2rem;
  }

  &__icon {
    margin-top: 1rem;
    fill: $color-brown-dark;
    transition: all 0.2s;

    &,
    &::before,
    &::after {
      width: 2rem;
      height: 2rem;
    }
  }

  &:hover &__icon {
    cursor: pointer;
    transform: scale(1.5);
  }

  &:active {
    transform: scale(0.8);
  }

  &--hidden {
    transform: translateY(50rem);
  }
}

.loading-logo {
  @include loading;
}

.loading-spinner {
  @include loading;
}

/* Transparent Overlay */
.loading-logo:before {
  @include loadingBackground;
}

.loading-spinner:before {
  @include loadingBackground(0.8);
}

/* :not(:required) hides these rules from IE9 and below */
.loading-logo:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading-spinner:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading-logo:after {
  content: "";
  display: block;
  font-size: 10px;
  width: 40em;
  height: 40em;
  margin-top: -20em;
  margin-left: -19em;
  animation: load 1.5s infinite 0s ease-in-out;
  background-image: url(../public/img/ale-baja-logo-three-colors.svg);

  @include respond(phone) {
    font-size: 5px;
  }
}

.loading-spinner:after {
  content: "";
  display: block;
  font-size: 15px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  animation: spinner 1s infinite 0s ease-in-out;
  border-radius: 0.5em;

  box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
    rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) -1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
    rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load {
  0% {
    opacity: 0.08;
    /*         font-size: 10px; */
    /* 				font-weight: 400; */
    filter: blur(5px);
    letter-spacing: 3px;
  }
  100% {
    /*         opacity: 1; */
    /*         font-size: 12px; */
    /* 				font-weight:600; */
    /* 				filter: blur(0); */
  }
}
